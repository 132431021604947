<template>
    <div class="container">
        <header class="jumbotron">
            <h3>{{content}}</h3>
            <h3>{{ content.id }}</h3>
            <h3>{{ content.isActive }}</h3>

        </header>
    </div>
</template>

<script>
    import UserService from "../services/user.service";

    export default {
        name: "Student",
        data() {
            return {
                content: "",
                temp: {},
            };
        },
        mounted() {
            UserService.getStudentBoard().then(
                (response) => {
                    this.content = response.data;
                },
                (error) => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        computed: {
            sex: function() {
                return JSON.parse(this.content)
            }
        }
    };
</script>
